var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:_vm.$vuetify.breakpoint.mdAndUp
      ? 'py-12 px-15'
      : _vm.$vuetify.breakpoint.sm
      ? 'py-12 px-6'
      : 'py-10 px-2',attrs:{"elevation":"0","rounded":"lg","width":"546","color":_vm.$vuetify.breakpoint.mdAndUp
      ? _vm.$vuetify.theme.dark
        ? 'background_normal'
        : 'background_light'
      : 'transparent'}},[_c('div',{staticClass:"mx-3"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"d-flex flex-column align-center mb-2 py-5",class:_vm.$vuetify.breakpoint.xs ? 'px-0' : 'px-3',staticStyle:{"gap":"8px"}},[_c('span',{staticClass:"h3 bold onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.title"))+" ")]),_c('span',{staticClass:"regular onSurface_normal--text text-center",class:_vm.$vuetify.breakpoint.mdAndUp ? 'title-2' : 'subtitle-1'},[_vm._v(" "+_vm._s(_vm.$t("signup.subtitle"))+" ")])]),_c('div',{staticClass:"d-flex flex-column my-7",staticStyle:{"gap":"26px"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"12px"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[_c('div',[_c('span',{staticClass:"title-2 regular onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.cellNumber"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),(!_vm.disabled.phone)?_c('v-select',{staticClass:"select",attrs:{"rules":[_vm.rules.required],"items":_vm.countries,"item-text":"name","item-value":"name","required":"","hide-details":"auto","attach":".select","flat":"","solo":"","outlined":"","height":"53","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"menu-props":{
                maxHeight: 400,
                offsetY: true,
                contentClass: 'elevation-1',
              },"disabled":_vm.verified || _vm.verifySended == true},on:{"change":_vm.onSelectChange,"update:error":function($event){_vm.validation.country = !$event}},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"6px"}},[_c('span',{staticClass:"primary--text subtitle-2 regular"},[_vm._v(" "+_vm._s(_vm.$t("signup.regionLabel"))+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"6px"}},[_c('v-img',{attrs:{"max-width":"22","src":_vm.flagFileName(item)}}),_c('span',{staticClass:"text-capitalize onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ko" ? item.name_kor : item.name)+" ("+_vm._s(item.code)+") ")])],1)])]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"6px","max-width":"100%"}},[_c('v-img',{attrs:{"max-width":"22","max-height":"16","src":_vm.flagFileName(item)}}),_c('span',{staticClass:"text-capitalize onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ko" ? _vm.$vuetify.breakpoint.mdAndUp ? `${item.name_kor} (${item.code}) (${item.name})` : `${item.name_kor} (${item.code})` : `${item.name} (${item.code})`)+" ")])],1)]}},{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}],null,false,1323374495),model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.regionLabel"))+" ")])]),_c('template',{slot:"append"},[_c('v-icon',{attrs:{"size":"16","color":"onSurface_light"}},[_vm._v(" mdi-chevron-down ")])],1)],2):_vm._e()],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.phone],"required":"","hide-details":"auto","flat":"","dense":"","solo":"","outlined":"","single-line":"","height":"53","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"disabled":_vm.verified ||
                _vm.verifySended == true ||
                _vm.verifyLoading ||
                _vm.disabled.phone},on:{"update:error":function($event){_vm.validation.phoneNumber = !$event}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}]),model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.phoneLabel"))+" ")])])],2),(!_vm.disabled.phone)?_c('v-btn',{staticClass:"title-1 bold",style:(_vm.verifySended == -1 ? 'transition: 1s ' : ''),attrs:{"color":"primary","elevation":"0","height":"53","loading":_vm.verifyLoading,"disabled":!(_vm.validation.phoneNumber && _vm.validation.country) ||
                _vm.verifySended == true ||
                _vm.verifyLoading ||
                _vm.verified ||
                _vm.disabled.phone},on:{"click":function($event){return _vm.requestVerify()}}},[_vm._v(_vm._s(_vm.verifySended == -1 ? _vm.$t("signup.reverifyBtn") : _vm.$t("signup.verifyBtn"))+" ")]):_vm._e()],1),(!_vm.disabled.phone)?_c('v-text-field',{ref:"verifier",attrs:{"rules":[_vm.rules.required, _vm.rules.verify],"required":"","type":"number","hide-spin-buttons":"","hide-details":"auto","single-line":"","flat":"","dense":"","solo":"","outlined":"","height":"53","loader-height":"2","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"disabled":!(_vm.verifySended && !_vm.verifyLoading) || _vm.verified},on:{"update:error":function($event){return _vm.verifyInput($event)}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}],null,false,2609874459),model:{value:(_vm.form.verifyCode),callback:function ($$v) {_vm.$set(_vm.form, "verifyCode", $$v)},expression:"form.verifyCode"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                    ? 'onSurface_light--text'
                    : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.verifyLabel"))+" ")])])],2):_vm._e()],1),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('div',[_c('span',{staticClass:"title-2 regular onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.name"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('span',{staticClass:"subtitle-2 regular red--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.nameWarning"))+" ")])]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.name],"required":"","hide-details":"auto","single-line":"","flat":"","dense":"","solo":"","outlined":"","height":"53","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"disabled":_vm.disabled.name},on:{"update:error":function($event){_vm.validation.name = !$event}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}]),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                    ? 'onSurface_light--text'
                    : 'background_dark--text'},[_vm._v(_vm._s(_vm.$t("signup.nameLabel")))])])],2)],1),(!_vm.snsSignUp)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[_c('div',[_c('span',{staticClass:"title-2 regular onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.password"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('v-text-field',{attrs:{"rules":[_vm.rules.signUpPassword, _vm.rules.required],"required":"","hide-details":"auto","single-line":"","flat":"","dense":"","solo":"","outlined":"","height":"53","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"type":_vm.show1 ? 'text' : 'password'},on:{"update:error":function($event){_vm.validation.password = !$event}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}],null,false,2609874459),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"my-auto",attrs:{"size":"15"},on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_vm._v(" "+_vm._s(_vm.show1 ? "mdi-eye-off" : "mdi-eye")+" ")])],1),_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                    ? 'onSurface_light--text'
                    : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.passwordLabel"))+" ")])])],2),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.passwordCheck],"required":"","hide-details":"auto","single-line":"","flat":"","dense":"","solo":"","outlined":"","height":"53","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"type":_vm.show2 ? 'text' : 'password'},on:{"update:error":function($event){_vm.validation.passwordCheck = !$event}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}],null,false,2609874459),model:{value:(_vm.form.passwordCheck),callback:function ($$v) {_vm.$set(_vm.form, "passwordCheck", $$v)},expression:"form.passwordCheck"}},[_c('template',{slot:"append"},[_c('v-icon',{staticClass:"my-auto",attrs:{"size":"15"},on:{"click":function($event){_vm.show2 = !_vm.show2}}},[_vm._v(" "+_vm._s(_vm.show2 ? "mdi-eye-off" : "mdi-eye")+" ")])],1),_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                    ? 'onSurface_light--text'
                    : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.passwordCheckLabel"))+" ")])])],2)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"8px"}},[_c('div',[_c('span',{staticClass:"title-2 regular onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.email"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])]),_c('span',{staticClass:"subtitle-2 regular red--text"},[_vm._v(" "+_vm._s(_vm.$t("signup.emailWarning"))+" ")])]),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"required":"","hide-details":"auto","single-line":"","flat":"","dense":"","solo":"","outlined":"","height":"53","disabled":_vm.disabled.email,"background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light'},on:{"update:error":function($event){_vm.validation.email = !$event}},scopedSlots:_vm._u([{key:"message",fn:function({ message }){return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}]),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
                    ? 'onSurface_light--text'
                    : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("signup.emailLabel"))+" ")])])],2)],1)]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"14px"}},[_c('div',{staticClass:"d-flex align-center hover-pointer",on:{"click":function($event){_vm.selectedAll = !_vm.selectedAll}}},[_c('v-icon',{attrs:{"color":_vm.selectedAll ? 'primary' : 'onSurface_normal',"size":"18"}},[_vm._v(" "+_vm._s(_vm.selectedAll ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('span',{staticClass:"ml-1 onSurface_normal--text subtitle-1 regular"},[_vm._v(" "+_vm._s(_vm.$t("signup.acceptAll"))+" ")])],1),_c('v-divider'),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"12px"}},[_c('div',{staticClass:"d-flex align-center hover-pointer",on:{"click":function($event){_vm.form.termsOfUse = !_vm.form.termsOfUse}}},[_c('v-icon',{attrs:{"color":_vm.form.termsOfUse ? 'primary' : 'onSurface_normal',"size":"18"}},[_vm._v(" "+_vm._s(_vm.form.termsOfUse ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('div',[_c('span',{staticClass:"ml-1 onSurface_normal--text subtitle-1 regular"},[_vm._v(" "+_vm._s(_vm.$t("signup.termsOfUse"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center hover-pointer",on:{"click":function($event){_vm.form.privacyPolicy = !_vm.form.privacyPolicy}}},[_c('v-icon',{attrs:{"color":_vm.form.privacyPolicy ? 'primary' : 'onSurface_normal',"size":"18"}},[_vm._v(" "+_vm._s(_vm.form.privacyPolicy ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('div',[_c('span',{staticClass:"ml-1 onSurface_normal--text subtitle-1 regular"},[_vm._v(" "+_vm._s(_vm.$t("signup.privacyPolicy"))+" ")]),_c('span',{staticClass:"red--text"},[_vm._v(" * ")])])],1),_c('v-spacer'),_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":"16","height":"16","text":""},on:{"click":function($event){return _vm.windowOpen(
                  'https://docs.madeall3d.com/660dd9c7-43aa-4824-9eef-e571d143dd2d'
                )}}},[_c('v-icon',{attrs:{"size":"16","color":"onSurface_light"}},[_vm._v(" mdi-arrow-right ")])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex align-center hover-pointer",on:{"click":function($event){_vm.form.marketing = !_vm.form.marketing}}},[_c('v-icon',{attrs:{"color":_vm.form.marketing ? 'primary' : 'onSurface_normal',"size":"18"}},[_vm._v(" "+_vm._s(_vm.form.marketing ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")]),_c('span',{staticClass:"ml-1 onSurface_normal--text subtitle-1 regular"},[_vm._v(" "+_vm._s(_vm.$t("signup.marketing"))+" ")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"pa-0",attrs:{"min-width":"16","height":"16","text":""},on:{"click":function($event){return _vm.windowOpen(
                  'https://docs.madeall3d.com/a7ed6b0b-92db-4494-adf9-c0a7491c9c1f'
                )}}},[_c('v-icon',{attrs:{"size":"16","color":"onSurface_light"}},[_vm._v(" mdi-arrow-right ")])],1)],1)])],1),_c('v-btn',{staticClass:"mt-11 mb-13",attrs:{"width":"100%","height":"53","color":"primary","elevation":"0","disabled":!(
            _vm.valid &&
            this.form.privacyPolicy &&
            this.form.termsOfUse &&
            (_vm.verified || _vm.disabled.phone)
          )},on:{"click":function($event){return _vm.submit()}}},[_c('span',{staticClass:"title-1 bold"},[_vm._v(_vm._s(_vm.$t("signup.signUpBtn")))])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }